<template>
  <div class="restaurant">
    <div class="content">
      <el-row>
        <el-col :xs="24" :sm="12" :md="6" style="margin-right: 10px">
          <el-form label-width="60px">
            <el-form-item label="门店">
                <el-select v-model="cityID" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="12" :md="6">
            <el-button type="primary" @click="seach()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
        </el-col>
      </el-row>
      <div style="padding-top: 10px">
        <el-button type="primary" @click="add()">新增</el-button>
      </div>
      <div class="tableBox">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          max-height="650"
          border
          style="width: 100%">
          <el-table-column
            prop="createAt"
            label="创建时间"
            width="200">
          </el-table-column>
          <el-table-column
            prop="name"
            label="门店名"
            min-width="200">
          </el-table-column>
          <el-table-column
            prop="address"
            label="省市区"
            width="200">
          </el-table-column>
          <el-table-column
            prop="addressList"
            label="详细地址"
            min-width="200">
          </el-table-column>
          <el-table-column
            prop="userName"
            label="区域主管"
            width="200">
          </el-table-column>
          <el-table-column
            prop="longitude"
            label="经度">
          </el-table-column>
          <el-table-column
            prop="latitude"
            label="纬度">
          </el-table-column>
          <el-table-column
            prop="type"
            label="门店类型"
            width="200">
            <template slot-scope="scope">
              <span>{{ formatType(scope.row.type) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="150">
            <template slot-scope="scope">
                <el-button v-auth="['区域管理员']"
                           size="small"
                @click.native.prevent="editRow(scope.$index, scope.row)"
                type="primary">
                编辑
                </el-button>
                <el-button v-auth="['区域管理员']"
                           size="small"
                @click.native.prevent="deleteRow(scope.$index, tableData)"
                type="primary">
                停用
                </el-button>
            </template>
        </el-table-column>
        </el-table>
      </div>
      <div class="paginationBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          background
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <el-dialog
        :title="dialogTitle"
        :visible.sync="updateVisible"
        :before-close="beforeClose"
        width="30%">
        <el-form 
          ref="form"
          :model="form"
          :rules="rules"
          label-width="90px">
          <el-row :gutter="20">
          <el-col :span="24">
          <el-form-item label="门店名称" prop="name">
            <el-input placeholder="请输入门店名称" v-model.trim="form.name"></el-input>
          </el-form-item>
         </el-col>
         <el-col :span="8">
          <el-form-item label="省" prop="province" label-width="40px">
            <el-select ref="provinceRef" v-model="form.province" placeholder="请选择省" style="width: 100%" :clearable="true" @change="provinceChange($event, 2)">
            <el-option
              v-for="item in provinceList"
              :key="item.value"
              :label="item.label" 
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="8">
          <el-form-item label="市" prop="city"  label-width="40px">
            <el-select ref="cityRef" v-model="form.city" placeholder="请先选择省" style="width: 100%" :clearable="true" @change="cityChange($event, 3)">
            <el-option
              v-for="item in cityList"
              :key="item.value"
              :label="item.label" 
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="8">
          <el-form-item label="区" prop="region"  label-width="40px">
            <el-select ref="regionRef" v-model="form.region" placeholder="请先选择市" style="width: 100%" :clearable="true" @change="$forceUpdate()">
            <el-option
              v-for="item in regionList"
              :key="item.value"
              :label="item.label" 
              :value="item.value">
            </el-option>
          </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="详细地址" prop="addressList">
            <el-input placeholder="请输入详细地址" v-model.trim="form.addressList"></el-input>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="经度" prop="longitude">
            <el-input placeholder="请输入经度" v-model.trim="form.longitude"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="纬度" prop="latitude">
            <el-input placeholder="请输入纬度" v-model.trim="form.latitude"></el-input>
          </el-form-item>
          <el-col :span="24">
          </el-col>
          <el-form-item label="主管" prop="userId">
            <el-select v-model="form.userId" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in userList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
          <el-col :span="24">
          <el-form-item label="类型" prop="type">
            <el-select v-model="form.type" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
        </el-form-item>
      </el-col>
      </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="beforeClose()">取 消</el-button>
          <el-button type="primary" @click="handleConfirm()">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="停用"
        :visible.sync="delVisible"
        :before-close="beforeClose"
        width="30%">
        <span>是否停用门店?</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="delVisible = false; itemId = -1">取 消</el-button>
            <el-button type="primary" @click="delVisible = false; updateSiteStatus(itemId)">确 定</el-button>
        </span>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import { siteList, siteInfoList, siteInfo, addSite, updateSiteStatus, regInfoList} from '../../api/restaurant';
import {getList} from '../../api/user';
import siteType from '../../config/siteType.json';

export default {
  data() {
    const validateLongitude = (_, value, callback) => {
      var longreg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{1,15})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,15}|180)$/
      if (!longreg.test(value)) {
        callback(new Error('经度整数部分为0-180,小数部分为0到15位!'))
      }
      callback()
    }
    const validateLatitude = (_, value, callback) => {
      var latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{1,15}|90\.0{0,15}|[0-8]?\d{1}|90)$/
      if (!latreg.test(value)) {
        callback(new Error('纬度整数部分为0-90,小数部分为0到15位!'))
      }
      callback()
    }
    return {
      itemId: -1,
      cityID: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      tableLoading: true,
      delVisible: false,
      updateVisible: false,
      dialogTitle: '',
      //省
      provinceList:[],
      //市
      cityList:[],
      //区
      regionList:[],
      //code
      codeArray: [],
      //主管
      userList: [],
      typeList: siteType.list,
      siteIdList: [],
      form: {
        name: '',
        address: '',
        addressCode: '',
        addressList: '',
        longitude: '',
        latitude: '',
        userId: '',
        type: '',
        province: '',
        city: '',
        region: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入门店名称', trigger: 'blur' },
        ],
        addressList: [
          { required: true, message: '请输入详细地址', trigger: 'blur' },
        ],
        longitude: [
          { required: true, message: '请输入经度', trigger: 'blur' },
          { validator: validateLongitude, trigger: 'change' },
        ],
        latitude: [
          { required: true, message: '请输入纬度', trigger: 'blur' },
          { validator: validateLatitude, trigger: 'change' },
        ],
        userId: [
          { required: true, message: '请选择主管', trigger: 'change' },
        ],
        type: [
          { required: true, message: '请选择类型', trigger: 'change' },
        ],
        province: [
          { required: true, message: '请选择省', trigger: 'change' },
        ],
        city: [
          { required: true, message: '请选择市', trigger: 'change' },
        ],
        region: [
          { required: true, message: '请选择区', trigger: 'change' },
        ]
      },
    }
  },
  mounted() {
    /**
     * @author XU
     */
    this.siteList();
    this.siteInfoList();
     //主管列表
     this.userInfoList();
     //加载省列表
     this.provinceInfoList();
  },
  methods: {
    async siteList() {
      try {
        const params = {
          page: this.currentPage,
          limit: this.pageSize
        };
        this.tableLoading = true;
        this.tableData = [];
        let data = await siteList(params);
        if (data && data.siteList) {
          this.total = data.total;
          this.tableData = data.siteList;
        } else {
          this.total = 0;
        }
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    async siteInfo() {
      const regExp = /^[0-9]*$/;
      if (!regExp.test(this.cityID)) {
        this.$message({
          message: '请输入数字',
          type: 'warning'
        })
        return
      }
      try {
        const params = {
          id: this.cityID
        };
        this.tableLoading = true;
        let data = await siteInfo(params);
        const tableList = [];
        this.total = 0;
        if (data !== null) {
          tableList.push(data);
          this.total = 1;
        }
        this.tableData = tableList;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    async userInfoList() {
      try {
        let data = await getList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.user
          })
        })
        this.userList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
    },
    //省下拉
    async provinceInfoList() {
      try {
        
        const params = {
          level : 1,
        }
        let data = await regInfoList(params);
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.regCode,
            label: item.regName
          })
        })
        this.provinceList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
    },

    //省市联动
    async provinceChange(code,level) {
      this.form.city = ""
      this.form.region = ""
      try {
        
        const params = {
          level : level,
          reg_code : code
        }
        let data = await regInfoList(params);
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.regCode,
            label: item.regName
          })
        })
        this.cityList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
    },

    //市区联动
    async cityChange(code,level) {
      this.form.region = ""
      try {
        
        const params = {
          level : level,
          reg_code : code
        }
        let data = await regInfoList(params);
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.regCode,
            label: item.regName
          })
        })
        this.regionList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
    },
    //新增
    async addSite() {
      try {
        this.form.address = this.$refs.provinceRef.selected.label.toString().concat("/",this.$refs.cityRef.selected.label.toString(),"/",this.$refs.regionRef.selected.label.toString())
        this.form.addressCode =this.form.province.toString().concat(",",this.form.city.toString(),",",this.form.region.toString())
        const params = {
          name:this.form.name,
          addressList:this.form.addressList,
          address:this.form.address,
          addressCode:this.form.addressCode,
          userId:this.form.userId,
          longitude:this.form.longitude,
          latitude:this.form.latitude,
          type:this.form.type
        };
        if (this.itemId != -1 && this.dialogTitle === '编辑') {
          params.id = this.itemId;
        }
        await addSite(params);
        this.siteList();
      } catch (error) {
        console.log("error:", error)
      }
      this.beforeClose();
    },
    async updateSiteStatus(id) {
      try {
        const params = {
          id
        };
        await updateSiteStatus(params);
        this.siteList();
      } catch (error) {
        console.log("error:", error)
      }
    },
    add() {
      this.dialogTitle = "新增";
      this.updateVisible = true;
      // this.userInfoList();
      // this.provinceInfoList();
    },
    editRow(index, rows) {
      this.dialogTitle = "编辑";
      //this.form = JSON.parse(JSON.stringify(rows));
      this.form = rows;
      this.form.userId= rows.userId;
      this.form.type= rows.type;
      //获取省市区code的List
      this.codeArray = rows.addressCode.split(",")
      //回显省级下拉
      this.form.province =parseInt(this.codeArray[0]);
      //加载省对应的市列表
      this.provinceChange(this.codeArray[0], 2)
      //回显市级下拉
      this.form.city = parseInt(this.codeArray[1]);
      //加载市对应的区列表
      this.cityChange(this.codeArray[1], 3)
      //回显区级下拉
      this.form.region = parseInt(this.codeArray[2]);
      this.itemId = rows.id;
      this.form.longitude = rows.longitude.toString();
      this.form.latitude = rows.latitude.toString();
      this.updateVisible = true;
    },
    deleteRow(index, list) {
      this.delVisible = true;
      this.itemId = list[index].id;
    },
    beforeClose() {
      this.dialogTitle = "";
      this.updateVisible = false;
      this.$refs['form'].resetFields();
      this.$data.form=JSON.parse(JSON.stringify(this.$options.data().form))  
    },
    handleConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
            this.addSite();
        } else {
            console.log('error submit!!');
            return false;
        }
      });
    },
    handleSizeChange(size) {
        this.pageSize = size;
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.siteList();
    },
    seach() {
      this.siteInfo();
    },
    reset() {
      this.cityID = "";
      this.currentPage = 1;
      this.pageSize = 10,
      this.siteList();
    },
    formatType(type) {
      let result = ""
      switch (type + "") {
        case "1":
          result = "云达";
          break;
        case "2":
          result = "永辉到家";
          break;
        case "3":
          result = "永辉绿标";
          break;
        case "4":
          result = "必胜客";
          break;
        case "5":
          result = "肯德基";
          break;
        case "6":
          result = "红标店";
          break;
        default:
          break;
      }
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
.restaurant {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>